import React from "react";
import "./index.css";
import Navbar from "./components/SimpleComponents/Navbar.js";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import HomePage from "./pages/homepage.js";
import Bio from "./pages/bio.js";
import Gigs from "./pages/gigs.js";
import Merch from "./pages/merch.js";
import Contact from "./pages/contact.js";
import News from "./pages/news.js";

function App2() {
  // Write a page saying under construction
  return (
    <div>
      <h1>Under Construction</h1>
    </div>
  );
}
function App() {
  return (
    <>
      <Router>
        <Navbar />
        <Routes>
          <Route exact path="/" element={<HomePage />} />
          <Route path="/bio" element={<Bio />} />
          <Route path="/gigs" element={<Gigs />} />
          <Route path="/news" element={<News />} />
          <Route path="/merch" element={<Merch />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
      </Router>
    </>
  );
}

export default App2;
